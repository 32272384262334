<template>
  <video :src="src" ref="videoElement" style="width: auto; height: auto;" controls playsinline></video>
</template>

<script>
export default {
  data() {
    return {
      previousCurrentTime: 0,
    };
  },
  emits: ['tracking', 'error'],
  methods: {
    onTimeUpdate(event) {
      this.$emit('tracking', this.$refs.videoElement.currentTime);
    },
    onSeeking(evt) {
      this.$emit('time-changed-by-client', this.$refs.videoElement.currentTime);
    },
  },
  mounted() {
    this.$refs.videoElement.addEventListener('seeking', this.onSeeking);
    this.$refs.videoElement.addEventListener('timeupdate', this.onTimeUpdate);
    this.$refs.videoElement.addEventListener('loadedmetadata', this.onLoadedMetadata);
    this.$refs.videoElement.addEventListener('error', (event) => {
      this.$emit('error', 'Video could not be loaded');
    });
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    goTo: {
      type: Number,
      required: false,
    },
  },
  unmount() {
    this.$refs.videoElement.removeEventListener('timeupdate', this.onTimeUpdate);
    this.$refs.videoElement.removeEventListener('loadedmetadata', this.onLoadedMetadata);
  },
  watch: {
    goTo(newValue) {
      if (newValue !== null) {
        this.$refs.videoElement.currentTime = newValue;
      }
    },
  },
};
</script>

<style>

</style>

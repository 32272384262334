<template>
  <div>
    {{ currentTimecode }}
  </div>
</template>

<script>

export default {
  name: 'CurrentTimeBadge',

  computed: {
    currentTimecode() {
      if (!this.currentTime && this.currentTime !== 0) {
        this.$emit('refresh-current-timecode', null);
        return '--:--:--.---';
      }

      const theCurrentTime = !Number.isNaN(Number(this.currentTime)) ? Number(this.currentTime) : this.timeLongToSeconds(this.currentTime);
      const hours = Math.floor(theCurrentTime / 3600);
      const minutes = Math.floor((theCurrentTime / 60) % 60);
      const seconds = Math.floor(theCurrentTime % 60);
      const milliseconds = theCurrentTime.toString().slice(-3);
      let frames = this.frameRate ? Math.round((theCurrentTime * this.frameRate) % this.frameRate) : null;

      if (frames && frames === this.frameRate) {
        frames = 0;
      }

      this.$emit('refresh-current-timecode', `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}${(frames || frames === 0) ? `:${frames.toString().padStart(2, '0')}` : `.${milliseconds.toString().padStart(3, '0')}`}`;
    },
  },

  methods: {
    timeLongToSeconds(timeInString) {
      const time = timeInString
        .split(/[:.]/)
        .map((value, index) => {
          if (index === 3) return value.padEnd(3, '0');
          return value.padEnd(2, '0');
        })
        .map((value) => parseFloat(value, 10));

      const hours = time[0] * 3600;
      const minutes = time[1] * 60;
      const seconds = time[2];
      const milliseconds = time[3] / 1000;

      return hours + minutes + seconds + milliseconds;
    },
  },
  props: {
    currentTime: {
      type: String,
      required: true,
    },
    frameRate: {
      default: null,
      type: Number,
      required: false,
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="w-100">
    <div class="spinner-border text-light" role="status" v-if="reloadingComments">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="row" v-if="commentReplies">
      <mdb-container v-if="commentReplies.length > 0">
        <mdb-collapse
          id="collapse"
          :toggleTag="['button']"
          :toggleClass="['btn btn-light btn-sm']"
          :toggleText="['SEE REPLIES']"
        >
          <div v-for="(comment, index) in commentReplies" :key="index">
            <div class="comments-container">
              <div class="row">
                <div class="col-8 padding-0">
                  <p class="comment"><b>{{comment.employee.nickname}}</b></p>
                </div>
                <div class="col-3 padding-0" v-if="comment.canedit && !comment.deletedat">
                  <a @click="deleteVideoComment(comment.videocommentid)" class="m-1 float-right" href="#!" role="button" style="color: rgb(59, 89, 152);">
                    <mdb-icon iconStyle="fab" icon="trash-alt" size="sm"></mdb-icon>
                  </a>
                  <a @click="editVideoComment(index)" class="m-1 float-right" role="button" style="color: rgb(59, 89, 152);">
                    <mdb-icon iconStyle="fab" icon="edit" size="sm"></mdb-icon>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="w-100 col-12">
                  <p class="comment" v-if="!comment.deletedat && !comment.isEditing" >
                    <pre class="comment">{{comment.comment}}</pre>
                  </p>
                  <p class="comment" v-else-if="comment.deletedat && !comment.isEditing">
                    <i><mdb-icon iconStyle="fas" icon="comment-slash"></mdb-icon> This message has been deleted</i>
                  </p>
                  <div class="comment" v-else-if="comment.isEditing">
                    <textarea class="comment" v-model="comment.comment"></textarea>
                    <a @click="cancelVideoComment(comment.videocommentid, index)" class="m-1" href="#!" role="button" style="color: rgb(59, 89, 152);" title="Cancel">
                      <mdb-icon iconStyle="fas" icon="window-close" size="sm"></mdb-icon>
                    </a>
                    <a @click="updateVideoComment(comment.videocommentid, comment.comment)" class="m-1" role="button" style="color: rgb(59, 89, 152);" title="Update">
                      <mdb-icon iconStyle="fas" icon="save" size="sm"></mdb-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-collapse>
      </mdb-container>
    </div>
    <div class="row d-flex flex-column align-items-end">
      <button v-if="!showReplyBox" class="btn btn-primary btn-sm" @click="toggleShowReplyBox()">
        REPLY
      </button>
      <button v-else-if="showReplyBox" class="btn btn-light btn-sm" @click="toggleShowReplyBox()">
        CANCEL
      </button>
      <div class="w-100 d-flex flex-wrap justify-content-end" v-if="showReplyBox">
        <mdb-input
        :disabled="sendingMessage"
        class="mb-0 w-100"
        type="textarea"
        v-model="replyComment"
        label="Reply"
      ></mdb-input>
      <mdb-btn class="btn-sm" color="indigo" @click="saveCommentReply()" :disabled="sendingMessage">
        Send
        <div class="spinner-border text-light" role="status" v-if="sendingMessage">
          <span class="sr-only">Loading...</span>
        </div>
      </mdb-btn>
    </div>
  </div>
</div>
</template>

<script>
import Apicall from '@/libs/Apicall';
import {
  mdbBtn, mdbCollapse, mdbContainer, mdbIcon, mdbInput,
} from 'mdbvue';

export default {
  components: {
    mdbBtn,
    mdbCollapse,
    mdbContainer,
    mdbIcon,
    mdbInput,
  },
  computed: {
    endpointPrefix() {
      return !this.isClientReview ? 'api/task' : 'api/task-review';
    },
  },
  data() {
    return {
      lastCommentLength: 0,
      loadingComments: false,
      sendingMessage: false,
      reloadingComments: false,
      showReplyBox: false,
      commentReplies: [],
      refreshIntervalHandler: null,
      editingComments: false,
    };
  },
  mounted() {
    this.refreshIntervalHandler = setInterval(() => {
      if (!this.loadingComments && !this.editingComments) {
        this.getVideoComments(this.taskId, 0, 0);
      }
    }, this.refreshRate);

    this.getAllRepliesForVideoComment(this.comments);
  },
  beforeDestroy() {
    clearInterval(this.refreshIntervalHandler);
  },
  emits: ['select-timecode', 'loading-comments'],
  methods: {
    getVideoComments(taskId, offset, limit) {
      const apiCall = new Apicall();

      const params = new URLSearchParams();
      params.append('taskid', taskId);
      params.append('offset', offset ?? 0);
      params.append('limig', limit ?? 1000);
      params.append('deliveryIndex', this.deliveryIndex ?? 0);

      this.loadingComments = true;

      apiCall.call('GET', `${this.endpointPrefix}/videocomments?${params.toString()}`).then((response) => {
        if (response.status === 'OK') {
          const commentsResponse = response.data.map((comment) => (
            {
              ...comment,
              isEditing: false,
            }
          ));
          this.getAllRepliesForVideoComment(commentsResponse);
        } else {
          this.$emit('error', response.msg);
        }
      }).catch((error) => {
          this.$emit('error', error);
      }).finally(() => {
        this.loadingComments = false;
      });
    },
    deleteVideoComment(videoCommentId) {
      const apiCall = new Apicall();
      this.loadingComments = true;
      this.editingComments = true;
      apiCall.call('POST', `${this.endpointPrefix}/delete-video-comment`, { commentId: videoCommentId, deliveryIndex: this.deliveryIndex })
      .then((response) => {
        if (response.status === 'OK') {
          this.reloadingComments = true;
        } else {
          this.$emit('error', response.msg);
        }
      }).catch((error) => {
          this.$emit('error', error);
      }).finally(() => {
        this.getVideoComments(this.taskId, 0, 0);
        this.loadingComments = false;
        this.reloadingComments = false;
        this.editingComments = false;
      });
    },
    editVideoComment(index) {
      this.loadingComments = true;
      this.commentReplies[index].isEditing = true;
      this.editingComments = true;
    },
    updateVideoComment(_videoCommentId, _newComment) {
      const apiCall = new Apicall();
      apiCall.call('POST', `${this.endpointPrefix}/update-video-comment`, { commentId: _videoCommentId, newComment: _newComment, deliveryIndex: this.deliveryIndex })
      .then((response) => {
        if (response.status === 'OK') {
          this.reloadingComments = true;
        } else {
          this.$emit('error', response.msg);
        }
      }).catch((error) => {
        this.$emit('error', error);
      }).finally(() => {
        this.commentReplies = [];
        this.getVideoComments(this.taskId, 0, 0);
        this.loadingComments = false;
        this.reloadingComments = false;
        this.editingComments = false;
      });
    },
    cancelVideoComment(videoCommentId, index) {
      this.loadingComments = false;
      this.commentReplies[index].isEditing = false;
      this.getVideoComments(this.taskId, 0, 0);
      this.editingComments = false;
    },
    saveCommentReply() {
      const apiCall = new Apicall();

      this.sendingMessage = true;
      this.loadingComments = true;

      apiCall.call('POST', `${this.endpointPrefix}/videocomments`, {
          comment: this.replyComment,
          timecode: this.currentTimecode,
          taskid: this.taskId,
          employeeid: this.$store.state.id,
          replytoid: this.parentCommentId,
          deliveryIndex: this.deliveryIndex,
        })
        .then((response) => {
          if (response.status === 'OK') {
            this.reloadingComments = true;
            this.comment = '';
          } else {
            this.$emit('error', response.msg);
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        })
        .finally(() => {
          this.sendingMessage = false;
          this.reloadingComments = false;
          this.loadingComments = false;
          this.toggleShowReplyBox();
          this.commentReplies = [];
          this.getVideoComments(this.taskId, 0, 0);
        });
    },
    getAllRepliesForVideoComment(comments) {
      const filteredComments = comments.filter((comment) => comment.replytoid === this.parentCommentId);
      if (filteredComments.length > this.commentReplies.length) {
        this.commentReplies = [];
        setTimeout(() => {
          this.commentReplies = filteredComments;
        }, 100);
      } else {
        this.commentReplies = filteredComments;
      }
    },
    toggleShowReplyBox() {
      this.showReplyBox = !this.showReplyBox;
      this.replyComment = null;
    },
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    parentCommentId: {
      type: String,
      required: true,
    },
    taskId: {
      type: String,
      required: true,
    },
    currentTimecode: {
      type: String,
      required: true,
    },
    refreshRate: {
      type: Number,
      required: true,
    },
    deliveryIndex: {
      type: Number,
      required: true,
    },
    isClientReview: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    loadingComments(newValue) {
      this.$emit('loading-comments', newValue);

      if (!newValue && this.commentReplies && this.lastCommentLength !== this.commentReplies.length) {
        this.$emit('new-comment', this.commentReplies.length);
        this.lastCommentLength = this.commentReplies.length;
      }
    },
    editingComments(newValue) {
      this.$emit('editing-comments', newValue);
    },
  },
};
</script>

<style scoped>
.text-muted:hover {
text-decoration: underline !important;
}
p.comment {
padding-left: 35px;
}
pre.comment {
font-size: 1rem;
font-family: Avenir, Helvetica, Arial, sans-serif;
color: #2c3e50;
white-space: pre-wrap;       /* Since CSS 2.1 */
white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
white-space: -pre-wrap;      /* Opera 4-6 */
white-space: -o-pre-wrap;    /* Opera 7 */
white-space:pre-line;
word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
div.comment {
width: 100%;
}
textarea.comment {
width: 100%;
}
.padding-0 {
padding: 0px;
}
.comments-container {
  padding: 0px;
  margin: 0px;
  min-height: 140px;
}
</style>

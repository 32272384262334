<template>
  <mdb-row>
    <mdb-col md="6" lg="8" sm="12" v-if="task !== null">
      <mdb-card>
        <mdb-card-header>
          <div class="d-flex justify-content-between align-items-center">
            {{ isClientReview ? 'Order' : 'Taskqueue' }} #{{ task.taskqueueid }}. {{ task.queuename }}
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <!-- <div class="w-100 d-flex m-1 justify-content-between" v-if="taskDeliveryContent && taskDeliveryContent.length > 1">
            <mdb-btn color="primary" @click="changeDeliveryContent(false)" :disabled="deliveryIndex === 0">
              Previous Content
            </mdb-btn>
            <mdb-btn color="primary" @click="changeDeliveryContent(true)" :disabled="deliveryIndex===(taskDeliveryContent.length - 1)">
              Next Content
            </mdb-btn>
          </div> -->
          <!-- <b-carousel
            id="carousel"
            ref="carousel"
            controls
            background="#ffffff"
            style="text-shadow: 1px 1px 2px #313131;"
            :interval="0"
          > -->
            <div v-for="(currentImage, index) in imagesToReview" :key="index" style="display: grid; justify-content: center; grid-gap: 5px;">
              <div><h4 style="text-align: center;">{{currentImage.displayname}}</h4></div>
              <img :src="currentImage.url" :alt="currentImage.filename" style="max-width: 50vw; max-height: 75vh">
              <div style="text-align: center; margin-top: 10px;">
                <a :href="currentImage.downloadurl" download>
                  <mdb-btn color="primary">
                    Download image
                  </mdb-btn>
                </a>
              </div>
              <!-- <b-carousel-slide v-model="currentSlide" class="carousel-slide" :img-src="currentImage.url" :text="((index + 1) + '. ' + currentImage.displayname)" :img-alt="currentImage.filename"></b-carousel-slide> -->
            </div>
          <!-- </b-carousel> -->
          <div class="w-100 d-flex flex-column align-items-end">
            <mdb-input
              :disabled="sendingMessage"
              class="mb-0 w-100"
              type="textarea"
              v-model="comment"
              label="Comment"
            ></mdb-input>
            <div class="mb-2 w-100 d-flex justify-content-end align-content-center">
              <mdb-btn color="secondary" @click="saveComment" :disabled="sendingMessage">
                Send
                <div class="spinner-border text-light" role="status" v-if="sendingMessage">
                  <span class="sr-only">Loading...</span>
                </div>
              </mdb-btn>
            </div>
          </div>
          <!-- <div class="w-100 d-flex flex-row align-items-baseline justify-content-end">
            <mdb-input
              class="mb-0 m-2 w-30"
              type="text"
              v-model="imageToSearch"
              label="Search Image:"
            ></mdb-input>
            <div class="mb-0 m-2 d-flex justify-content-end align-content-center">
              <mdb-btn color="info" @click="navigateToImage()">
                Search
              </mdb-btn>
            </div>
          </div> -->
        </mdb-card-body>
      </mdb-card>
    </mdb-col>
    <mdb-col>
      <mdb-card>
        <mdb-card-header>
          <div class="d-flex justify-content-between align-items-center">
            Photo comments
            <div class="spinner-border text-dark" role="status" v-if="loadingComments && !editingComments">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body style="overflow: scroll; max-height: 80vh;" ref="cardBody">
          <video-chatbox
            v-if="deliveryIndex !== null"
            :is-client-review="isClientReview"
            :refresh-rate="8000"
            :task-id="taskId"
            :delivery-index="deliveryIndex"
            :frame-rate="null"
            :is-photo-review="true"
            @loading-comments="loadingComments = $event"
            @editing-comments="editingComments = $event"
            @new-comment="onNewComment"
            @error="$emit('error', $event)"
          ></video-chatbox>
        </mdb-card-body>
      </mdb-card>
    </mdb-col>
  </mdb-row>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbBtn,
  mdbCol,
  mdbInput,
  mdbRow,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';
import VideoChatbox from './VideoChatbox.vue';

export default {
  components: {
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbCol,
    mdbInput,
    mdbRow,
    VideoChatbox,
  },

  data() {
    return {
      loadingComments: false,
      sendingMessage: false,
      task: null,
      comment: '',
      editingComments: false,
      currentSlide: 0,
      imageToSearch: 1,
      taskDeliveryContent: [],
      deliveryContentIndex: null,
    };
  },

  computed: {
    endpointPrefix() {
      return this.isClientReview ? 'api/task-review' : 'api/task';
    },
    imagesToReview() {
      let imagesToReview = [];
      if (this.taskDeliveryContent[this.deliveryIndex].deliveryContent.type === 'image-file') {
        imagesToReview = [{
          filename: this.taskDeliveryContent[this.deliveryIndex].deliveryContent.filename,
          url: this.taskDeliveryContent[this.deliveryIndex].deliveryContent.url,
          displayname: this.taskDeliveryContent[this.deliveryIndex].deliveryContent.displayname,
        }];
      } else if (this.task.photosFromZipFile && this.taskDeliveryContent[this.deliveryIndex].deliveryContent.type === 'zip') {
        imagesToReview = this.task.photosFromZipFile;

        for (let i = 0; i < imagesToReview.length; i += 1) {
          imagesToReview[i].displayname = this.taskDeliveryContent[this.deliveryIndex].deliveryContent.displayname;
        }
      }

      return imagesToReview;
    },
  },

  methods: {
    onNewComment(commentsLength) {
      if (commentsLength) {
        this.$refs.cardBody.$el.scrollTop = this.$refs.cardBody.$el.scrollHeight;
      }
    },
    getInfos(taskId) {
      const apiCall = new Apicall();
      let next = false;
      let itemid = null;
      if (this.$route.params.next) {
        next = true;
      }
      if (this.$route.params.taskid) {
        itemid = this.$route.params.taskid;
      }

      apiCall
        .call('POST', `${this.endpointPrefix}/infos`, { taskid: taskId, itemid, next })
        .then((response) => {
          if (response.status === 'OK') {
            this.task = response.data;

            this.formatTaskDeliveryContent();
          } else {
            this.$emit('error', response.msg);
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        });
    },

    saveComment() {
      const apiCall = new Apicall();
      let next = false;
      let itemid = null;
      if (this.$route.params.next) {
        next = true;
      }
      if (this.$route.params.taskid) {
        itemid = this.$route.params.taskid;
      }

      this.sendingMessage = true;

      let storedName = null;
      let storedEmail = null;

      if (this.isClientReview) {
        storedName = localStorage.getItem('clientName');
        storedEmail = localStorage.getItem('clientEmail');
      }

      apiCall
        .call('POST', `${this.endpointPrefix}/videocomments`, {
          comment: this.comment,
          timecode: 0,
          taskid: this.taskId,
          employeeid: this.$store.state.id,
          deliveryIndex: this.deliveryIndex,
          next,
          itemid,
          clientName: storedName,
          clientEmail: storedEmail,
        })
        .then((response) => {
          if (response.status === 'OK') {
            this.comment = '';
          } else {
            this.$emit('error', response.msg);
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        })
        .finally(() => {
          this.sendingMessage = false;
        });
    },
    navigateToImage() {
      const nextImageIndex = this.imageToSearch - 1;
      if (nextImageIndex < 0 || nextImageIndex >= this.imagesToReview.length) {
        this.$emit('error', `There is no image number ${this.imageToSearch}`);
        return;
      }
      this.$refs.carousel.setSlide(nextImageIndex);
    },
    changeDeliveryContent(isNext = false) {
      const nextDeliveryIndex = isNext ? (this.deliveryIndex + 1) : (this.deliveryIndex - 1);

      if (!this.taskDeliveryContent || !this.taskDeliveryContent[nextDeliveryIndex]) {
        this.$emit('error', `There is a problem with the ${isNext ? 'next' : 'previous'} delivery content`);
        return;
      }

      this.deliveryContentIndex = this.taskDeliveryContent[nextDeliveryIndex].deliveryIndex;

      this.deliveryIndex = nextDeliveryIndex;
    },
    formatTaskDeliveryContent() {
      let deliveryContent = null;
      if (this.task.spotcheckof) {
        deliveryContent = this.task.spotcheckof.deliverycontent;
      } else {
        deliveryContent = this.task.deliverycontent;
      }

      if (!deliveryContent) {
        this.$emit('error', 'The delivery content of this task was not found');
        return;
      }

      const newTaskDeliveryContent = [];
      deliveryContent.forEach((deliveryElement, index) => {
        if (deliveryElement?.type === 'image-file' || (deliveryElement?.type === 'zip' && this.task.photosFromZipFile)) {
          newTaskDeliveryContent[index] = {
            deliveryIndex: index,
            deliveryContent: deliveryElement,
          };
        }
      });

      // if (newTaskDeliveryContent.length > 0) {
      //   this.deliveryContentIndex = newTaskDeliveryContent[0].deliveryIndex;
      // }

      this.taskDeliveryContent = newTaskDeliveryContent;
    },
  },

  mounted() {
    if (this.taskId) {
      this.getInfos(this.taskId);
    }
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
    isClientReview: {
      type: Boolean,
      required: false,
      default: false,
    },
    deliveryIndex: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
  .card-header:first-child {
    font-weight: 700 !important;
  }

  .card-header > div {
    min-height: 2rem !important;
  }

  .carousel .carousel-item {
    height: 500px;
  }

  .carousel-slide > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 500px;
    width: 100px;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
  }

</style>

<template>
      <mdb-list-group>
        <div v-for="(comment, index) in comments" :key="index">
          <mdb-list-group-item v-if="!comment.replytoid">
            <div class="container">
              <div class="row">
                <div class="col-md-8">
                  <p v-if="comment.employee"><b>{{comment.employee?.email?.substring(0, comment.employee?.email?.indexOf('@') + 1)}}</b> ({{ comment.addedat }})</p>
                  <p v-else-if="comment.clientEmail"><b>{{comment.clientName}}</b><br>({{ comment.addedat }})</p>
                  <p v-else><b>{{ comment.user.nickname }}</b> ({{ comment.addedat }})</p>
                </div>
                <div class="col-md-4" v-if="comment.canedit && !comment.deletedat">
                  <a @click="deleteVideoComment(comment.videocommentid)" class="m-1 float-right" href="#!" role="button" style="color: rgb(59, 89, 152);">
                    <mdb-icon iconStyle="fab" icon="trash-alt" size="sm"></mdb-icon>
                  </a>
                  <a @click="editVideoComment(index)" class="m-1 float-right" role="button" style="color: rgb(59, 89, 152);">
                    <mdb-icon iconStyle="fab" icon="edit" size="sm"></mdb-icon>
                  </a>
                </div>
              </div>
              <div class="row">
                <p class="comment" v-if="!comment.deletedat && !comment.isEditing" ><pre class="comment">{{comment.comment}}</pre></p>
                <p class="comment" v-else-if="comment.deletedat && !comment.isEditing">
                  <i><mdb-icon iconStyle="fas" icon="comment-slash"></mdb-icon> This message has been deleted</i>
                </p>
                <div class="comment" v-else-if="comment.isEditing">
                  <textarea class="comment" v-model="comment.comment"></textarea>
                  <a @click="cancelVideoComment(comment.videocommentid, index)" class="m-1" href="#!" role="button" style="color: rgb(59, 89, 152);" title="Cancel">
                    <mdb-icon iconStyle="fas" icon="window-close" size="sm"></mdb-icon>
                  </a>
                  <a @click="updateVideoComment(comment.videocommentid, comment.comment)" class="m-1" role="button" style="color: rgb(59, 89, 152);" title="Update">
                    <mdb-icon iconStyle="fas" icon="save" size="sm"></mdb-icon>
                  </a>
                </div>
              </div>
              <div v-if="!isPhotoReview" class="row">
                <div class="col-md-8">
                  <a class="text-muted" style="font-size: 0.9rem;" title="Go to timecode" @click="$emit('select-timecode', comment.timecode)">
                    <current-time-badge
                      :current-time="comment.timecode.toString()"
                      :frame-rate="frameRate"
                    ></current-time-badge>
                  </a>
                </div>
                <div class="col-md-4">
                  <small v-if="comment.modifiedat" class="float-right">Edited</small>
                </div>
              </div>
              <video-replybox
                :isClientReview="isClientReview"
                :comments="comments"
                :parent-comment-id="comment.videocommentid"
                :task-id="taskId"
                :delivery-index="deliveryIndex"
                :current-timecode="comment.timecode"
                :refresh-rate="refreshRate"
                @loading-comments="loadingComments = $event"
                @editing-comments="editingComments = $event"
              ></video-replybox>
            </div>
          </mdb-list-group-item>
        </div>
      </mdb-list-group>
</template>

<script>
import Apicall from '@/libs/Apicall';
import {
mdbListGroup, mdbListGroupItem, mdbIcon,
} from 'mdbvue';
import VideoReplybox from './VideoReplybox.vue';
import CurrentTimeBadge from './CurrentTimeBadge.vue';

export default {
  components: {
    mdbListGroup,
    mdbListGroupItem,
    mdbIcon,
    VideoReplybox,
    CurrentTimeBadge,
  },
  computed: {
    endpointPrefix() {
      return !this.isClientReview ? 'api/task' : 'api/task-review';
    },
  },
  data() {
    return {
      comments: [],
      lastCommentLength: 0,
      loadingComments: false,
      refreshIntervalHandler: null,
      editingComments: false,
    };
  },
  emits: ['select-timecode', 'loading-comments'],
  methods: {
    getVideoComments(taskId, offset, limit) {
      const apiCall = new Apicall();

      const params = new URLSearchParams();
      params.append('taskid', taskId);
      params.append('offset', offset ?? 0);
      params.append('limig', limit ?? 1000);
      params.append('deliveryIndex', this.deliveryIndex ?? 0);

      this.loadingComments = true;

      apiCall.call('GET', `${this.endpointPrefix}/videocomments?${params.toString()}`).then((response) => {
        if (response.status === 'OK') {
          this.comments = response.data.map((comment) => (
            {
              ...comment,
              isEditing: false,
            }
          ));
        } else {
          this.$emit('error', response.msg);
        }
      }).catch((error) => {
          this.$emit('error', error);
      }).finally(() => {
        this.loadingComments = false;
      });
    },
    deleteVideoComment(videoCommentId) {
      const apiCall = new Apicall();
      apiCall.call('POST', `${this.endpointPrefix}/delete-video-comment`, { commentId: videoCommentId, deliveryIndex: this.deliveryIndex }).then((response) => {
        if (response.status === 'OK') {
          this.getVideoComments(this.taskId, 0, 0);
        }
      }).catch((error) => {
          this.$emit('error', error);
      }).finally(() => {
        this.getVideoComments(this.taskId, 0, 0);
      });
    },
    editVideoComment(index) {
      this.editingComments = true;
      this.loadingComments = true;
      this.comments[index].isEditing = true;
    },
    timeLongToSeconds(timeInString) {
      if (!timeInString) return null;

      const time = timeInString
        .split(/[:.]/)
        .map((value, index) => {
          if (index === 3) return value.padEnd(3, '0');
          return value.padEnd(2, '0');
        })
        .map((value) => parseFloat(value, 10));

      const hours = time[0] * 3600;
      const minutes = time[1] * 60;
      const seconds = time[2];
      const milliseconds = time[3] / 1000;

      return hours + minutes + seconds + milliseconds;
    },
    updateVideoComment(_videoCommentId, _newComment) {
      const apiCall = new Apicall();
      apiCall.call('POST', `${this.endpointPrefix}/update-video-comment`, { commentId: _videoCommentId, newComment: _newComment, deliveryIndex: this.deliveryIndex }).then((response) => {
        if (response.status === 'OK') {
          this.getVideoComments(this.taskId, 0, 0);
        }
      }).catch((error) => {
          this.$emit('error', error);
      }).finally(() => {
        this.getVideoComments(this.taskId, 0, 0);
        this.editingComments = false;
      });
    },
    cancelVideoComment(videoCommentId, index) {
      this.loadingComments = false;
      this.comments[index].isEditing = false;
      this.editingComments = false;
    },
  },
  mounted() {
    this.refreshIntervalHandler = setInterval(() => {
      if (!this.loadingComments && !this.editingComments) {
        this.getVideoComments(this.taskId, 0, 0);
      }
    }, this.refreshRate);

    this.getVideoComments(this.taskId, 0, 0);
  },
  beforeDestroy() {
    clearInterval(this.refreshIntervalHandler);
  },
  props: {
    refreshRate: {
      type: Number,
      required: true,
    },

    taskId: {
      type: String,
      required: true,
    },

    deliveryIndex: {
      type: Number,
      required: true,
    },

    frameRate: {
      default: null,
      type: Number,
      required: false,
    },

    isPhotoReview: {
      type: Boolean,
      required: false,
      default: false,
    },

    isClientReview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    loadingComments(newValue) {
      this.$emit('loading-comments', newValue);

      if (!newValue && this.comments && this.lastCommentLength !== this.comments.length) {
        this.$emit('new-comment', this.comments.length);
        this.lastCommentLength = this.comments.length;
      }
    },
    editingComments(newValue) {
      this.$emit('editing-comments', newValue);
    },
  },
};
</script>

<style scoped>
  .text-muted:hover {
    text-decoration: underline !important;
  }
  p.comment {
    padding-left: 15px;
  }
  pre.comment {
    font-size: 1rem;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: #2c3e50;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space:pre-line;
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
  div.comment {
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
  }
  textarea.comment {
    width: 100%;
  }
</style>

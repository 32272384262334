<template>
  <mdb-row>
    <mdb-col md="6" lg="8" sm="12" v-if="task !== null">
      <mdb-card>
        <mdb-card-header>
          <div class="d-flex justify-content-between align-items-center">
            {{isClientReview ? 'Order' : 'Taskqueue'}} #{{ task.taskqueueid }}. {{ task.queuename }}
            {{isClientReview || (!this.task.spotcheckof?.employee && !this.task.employee) ? '' : `edited by ${this.task.spotcheckof ? this.task.spotcheckof.employee?.email?.substr(0, this.task.spotcheckof.employee?.email?.length / 2) : this.task.employee?.email?.substr(0, this.task.employee?.email?.length / 2)}`}}
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div v-for="(deliveryVideoElement, index) in videoElements" :key="index" style="display: grid; justify-content: center; grid-gap: 5px;">
            <div><h4 style="text-align: center;">{{deliveryVideoElement.displayname}}</h4></div>
            <video-tracking-panel
              style="max-width: 65vw; max-height: 75vh"
              @tracking="onTracking"
              :src="deliveryVideoElement.url"
              :go-to="timecodeSelected"
              @time-changed-by-client="updatingTime"
              @error="$emit('error', $event)"
            ></video-tracking-panel>
            <div style="text-align: center; margin-top: 10px;">
              <a :href="deliveryVideoElement.downloadurl" download>
                <mdb-btn color="primary">
                  Download video
                </mdb-btn>
              </a>
            </div>
          </div>
          <div class="w-100 d-flex flex-column align-items-end">
            <mdb-input
              :disabled="sendingMessage"
              class="mb-0 w-100"
              type="textarea"
              v-model="comment"
              label="General Comments HERE"
            ></mdb-input>
            <div class="mb-2 w-100 d-flex justify-content-end align-content-center">
              <mdb-btn color="secondary" @click="saveComment" :disabled="sendingMessage">
                Send
                <div class="spinner-border text-light" role="status" v-if="sendingMessage">
                  <span class="sr-only">Loading...</span>
                </div>
              </mdb-btn>
              <div class="m-auto">
                <mdb-badge color="info" class="p-2" style="font-size: 1rem">
                  <current-time-badge
                    :current-time="currentTime.toString()"
                    :frame-rate="frameRate"
                    @refresh-current-timecode="currentTimecode = $event;"
                  ></current-time-badge>
                </mdb-badge>
              </div>
            </div>
          </div>
          <div v-if="isClientReview && (csvContent.length > 0 || srtContent.length > 0) && deliveryIndex === 0 && !task.moreThanOneClientReviewTasks" class="subs-textareas">
            <div v-if="isClientReview && csvContent.length > 0" style="width: 100%;">
              <h4 style="font-size: 18px;" class="mt-3 ml-4">Motion graphics (EDITABLE)</h4>
              <textarea placeholder="There aren't any motions graphics at this time" class="ml-4" rows="3" style="width: 90%;" v-model="csvTextInTextarea"></textarea>
            </div>
            <div v-if="isClientReview && srtContent.length > 0" style="width: 100%;">
              <h4 style="font-size: 18px;" class="mt-3 ml-4">Manually edit the subtitles HERE</h4>
              <textarea ref="srtTextarea" placeholder="There aren't any subtitles at this time" class="ml-4" rows="3" style="width: 90%;" v-model="srtTextInTextarea"></textarea>
            </div>
          </div>
        <div v-if="isClientReview && (srtContent.length > 0 || csvContent.length > 0) && !task.moreThanOneClientReviewTasks && deliveryIndex === 0" class="mt-3 ml-4 message-box">
          <p>Important: The changes you manually make in the subtitles will be modified once you return the task.</p>
        </div>
        </mdb-card-body>
      </mdb-card>
    </mdb-col>
    <mdb-col>
      <mdb-card>
        <mdb-card-header>
          <div class="d-flex justify-content-between align-items-center">
            Video comments
            <div class="spinner-border text-dark" role="status" v-if="loadingComments && !editingComments">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body style="overflow: scroll; max-height: 80vh;" ref="cardBody">
          <video-chatbox
            :refresh-rate="8000"
            :task-id="taskId"
            :delivery-index="deliveryIndex"
            :frame-rate="frameRate"
            :is-client-review="isClientReview"
            @select-timecode="onSelectTimecode"
            @loading-comments="loadingComments = $event"
            @editing-comments="editingComments = $event"
            @new-comment="onNewComment"
            @error="$emit('error', $event)"
          ></video-chatbox>
        </mdb-card-body>
      </mdb-card>
    </mdb-col>
  </mdb-row>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbBtn,
  mdbCol,
  mdbInput,
  mdbRow,
  mdbBadge,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import VideoTrackingPanel from './VideoTrackingPanel.vue';
import VideoChatbox from './VideoChatbox.vue';
import CurrentTimeBadge from './CurrentTimeBadge.vue';

export default {
  components: {
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbCol,
    mdbInput,
    mdbRow,
    mdbBadge,
    VideoChatbox,
    VideoTrackingPanel,
    CurrentTimeBadge,
  },
  computed: {
    endpointPrefix() {
      return !this.isClientReview ? 'api/task' : 'api/task-review';
    },
    nonVideoElements() {
      if (!this.task) {
        return [];
      }

      let deliveryContent = null;

      if (this.task.spotcheckof) {
        deliveryContent = this.task.spotcheckof.deliverycontent;
      } else {
        deliveryContent = this.task.deliverycontent;
      }

      return deliveryContent.filter(
        (element) => element.type !== 'video-file',
      );
    },
    videoElements() {
      if (!this.task) {
        return [];
      }

      let deliveryContent = null;

      if (this.task.spotcheckof) {
        deliveryContent = this.task.spotcheckof.deliverycontent;
      } else {
        deliveryContent = this.task.deliverycontent;
      }

      const allVideoElements = deliveryContent;

      return allVideoElements.length > 0 ? [allVideoElements[this.deliveryIndex]] : [];
    },
    allElements() {
      if (!this.task) {
        return [];
      }

      let deliveryContent = null;

      if (this.task.spotcheckof) {
        deliveryContent = this.task.spotcheckof.deliverycontent;
      } else {
        deliveryContent = this.task.deliverycontent;
      }

      const allElements = deliveryContent;

      return allElements.length > 0 ? allElements : [];
    },
  },

  data() {
    return {
      currentTime: 0,
      loadingComments: false,
      timecodeSelected: null,
      sendingMessage: false,
      task: null,
      comment: '',
      editingComments: false,
      frameRate: null,
      currentTimecode: null,
      timeChangedByUser: null,
      srtTextInTextarea: '',
      srtTextIndex: null,
      csvTextInTextarea: '',
      csvTextIndex: null,
      srtFromDeliveryContent: [],
      csvFromDeliveryContent: [],
      textareaDelivery: null,
      srtFileFromTaskqueue: [],
      srtContent: [],
      srtPrevTimecodeNumber: [-1],
      csvContent: [],
      csvTitlesData: {},
      csvExtension: '',
      csvPrevTimecodeNumber: [-1],
      changedTime: null,
      idOfTaskWithSrt: null,
      idOfTaskWithCsv: null,
    };
  },
  watch: {
    srtFromDeliveryContent() {
      this.fetchSrtData();
    },
    csvFromDeliveryContent() {
      this.fetchCsvData();
    },
    currentTimecode() {
      this.showSrtDataInTextarea();
      this.showCsvDataInTextarea();
    },
    srtTextInTextarea() {
      this.saveTextIntoSrtArray();
    },
    csvTextInTextarea() {
      this.saveTextIntoCsvArray();
    },
    timeChangedByUser() {
      this.modifyPrevTimecodesNumber();
    },
  },
  methods: {
    timecodeToCurrentTime(timecode) {
      const timecodeParts = timecode.split(':');

      const hoursToSeconds = window.parseInt(timecodeParts[0]) * 3600;
      const minutesToSeconds = window.parseInt(timecodeParts[1]) * 60;

      return hoursToSeconds + minutesToSeconds + window.parseFloat(timecodeParts[2]);
    },

    onTracking(time) {
      this.currentTime = time;
    },

    updatingTime(time) {
      this.timeChangedByUser = time;
    },

    onNewComment(commentsLength) {
      if (commentsLength) {
        this.$refs.cardBody.$el.scrollTop = this.$refs.cardBody.$el.scrollHeight;
      }
    },

    getInfos(taskId) {
      const apiCall = new Apicall();
      let next = false;
      let itemid = null;
      if (this.$route.params.next) {
        next = true;
      }
      if (this.$route.params.taskid) {
        itemid = this.$route.params.taskid;
      }

      apiCall
        .call('POST', `${this.endpointPrefix}/infos`, { taskid: taskId, itemid, next })
        .then((response) => {
          if (response.status === 'OK') {
            this.task = response.data;
            this.getVideoFrameRate();
            this.getLastSrtAndCsvFromTaskqueue();
          } else {
            this.$emit('error', response.msg);
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        });
    },

    onSelectTimecode(timecode) {
      const seconds = this.timecodeToCurrentTime(timecode);

      this.timecodeSelected = seconds;
    },

    saveComment() {
      const apiCall = new Apicall();
      let next = false;
      let itemid = null;
      if (this.$route.params.next) {
        next = true;
      }
      if (this.$route.params.taskid) {
        itemid = this.$route.params.taskid;
      }

      this.sendingMessage = true;

      let storedName = null;
      let storedEmail = null;

      if (this.isClientReview) {
        storedName = localStorage.getItem('clientName');
        storedEmail = localStorage.getItem('clientEmail');
      }

      apiCall
        .call('POST', `${this.endpointPrefix}/videocomments`, {
          comment: this.comment,
          timecode: this.currentTimecode,
          taskid: this.taskId,
          employeeid: this.$store.state.id,
          deliveryIndex: this.deliveryIndex,
          next,
          itemid,
          clientName: storedName,
          clientEmail: storedEmail,
        })
        .then((response) => {
          if (response.status === 'OK') {
            this.comment = '';
          } else {
            this.$emit('error', response.msg);
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        })
        .finally(() => {
          this.sendingMessage = false;
        });
    },

    getVideoFrameRate() {
      let deliveryContent = null;
      if (this.task.spotcheckof) {
        deliveryContent = this.task.spotcheckof.deliverycontent;
      } else {
        deliveryContent = this.task.deliverycontent;
      }
      const videoMetadata = (deliveryContent && deliveryContent[this.deliveryIndex].metadata) ? deliveryContent[this.deliveryIndex].metadata : null;
      if (videoMetadata && videoMetadata.framerate) {
        const fpsArray = videoMetadata.framerate.split('/');
        this.frameRate = Number((Number(fpsArray[0]) / Number(fpsArray[1])).toFixed(2));
      }
    },

    saveCurrentTimecode(newTimeCode) {
      this.currentTimecode = newTimeCode;
    },
    getLastSrtAndCsvFromTaskqueue() {
      const apiCall = new Apicall();
      const taskqueueId = this.task.taskqueueid;
      apiCall
      .call('POST', 'api/task-review/get-last-srt-and-csv-from-taskqueue', { taskqueueId })
      .then((response) => {
        this.idOfTaskWithSrt = response.data.idOfTaskWithSrt;
        this.idOfTaskWithCsv = response.data.idOfTaskWithCsv;
        this.addLinksToTaskWithSrtAndCsv();
      });
    },
    addLinksToTaskWithSrtAndCsv() {
      const apiCall = new Apicall();
      apiCall
      .call('POST', 'api/task-review/get-url-links-for-task-with-srt-and-csv', { taskIdWithSrt: this.idOfTaskWithSrt, taskIdWithCsv: this.idOfTaskWithCsv })
      .then((response) => {
        if (response.data.srtFromDeliveryContent || response.data.csvFromDeliveryContent) {
          this.srtFromDeliveryContent = response.data.srtFromDeliveryContent !== null ? response.data.srtFromDeliveryContent : [];
          this.csvFromDeliveryContent = response.data.csvFromDeliveryContent !== null ? response.data.csvFromDeliveryContent : [];
        }
      });
    },
    fetchSrtData() {
      let srtFile = [];
      if (this.srtFromDeliveryContent.length > 0) {
        srtFile = this.srtFromDeliveryContent.filter((item) => item?.name === 'srt_file');
      }
      if (srtFile.length > 0) {
        // Get the last one
        const { url } = srtFile[srtFile.length - 1];
        const apiCall = new Apicall();
        apiCall
        .call('POST', 'api/task-review/get-srt-file-content', { link: url })
        .then((response) => {
          if (response.status === 'OK') {
            this.srtContent = response.data;
          }
        })
        .catch((error) => {
            this.loading = false;
            this.error = error;
        });
      }
    },
    fetchCsvData() {
      let csvFile = [];
      if (this.csvFromDeliveryContent.length > 0) {
        csvFile = this.csvFromDeliveryContent.filter((item) => item?.name === 'csvfile');
      }
      if (csvFile.length > 0) {
        const { url } = csvFile[0];
        const apiCall = new Apicall();
        apiCall
        .call('POST', 'api/task-review/get-csv-file-content', { link: url })
        .then((response) => {
          if (response.status === 'OK') {
            this.csvContent = response.data;
            this.csvExtension = response.complete.extension;
          }
        })
        .catch((error) => {
            this.loading = false;
            this.error = error;
        });
      }
    },
    editSrt() {
      // PASAR A UNA FUNCIÓN APARTE
      const unifyStarTimeAndStopTime = this.srtContent.map((element) => {
        const {
          number,
          startTime,
          stopTime,
          text,
        } = element;
        const srtObj = { number, time: `${startTime} --> ${stopTime}`, text };
        return srtObj;
      });

      const srtContentConvertedArr = unifyStarTimeAndStopTime.map((element) => Object.keys(element).map((key) => element[key]));
      const srtContentConvertedToArrStr = srtContentConvertedArr.map((element) => element.join('\n'));
      const srtContentToString = srtContentConvertedToArrStr.join('\n');

      const apiCall = new Apicall();
      apiCall
      .call('POST', 'api/task-review/edit-srt-content', { srtFile: srtContentToString, taskId: this.idOfTaskWithSrt })
      .then((response) => {
        if (response.status === 'OK') {
          if (!this.task.moreThanOneClientReviewTasks) {
            this.createFileModifiedEvent('SRT', this.idOfTaskWithSrt);
          }
        }
      });
    },
    editCsv() {
      const apiCall = new Apicall();
      apiCall
      .call('POST', 'api/task-review/edit-csv-content', { csvFile: this.csvContent.join('\n'), taskId: this.idOfTaskWithCsv, extension: this.csvExtension })
      .then((response) => {
        if (response.status === 'OK') {
          if (!this.task.moreThanOneClientReviewTasks) {
            this.createFileModifiedEvent('CSV', this.idOfTaskWithCsv);
          }
        }
      });
    },
    createFileModifiedEvent(type, taskId) {
      const apiCall = new Apicall();
      apiCall
      .call('POST', 'api/task-review/create-file-modified-event', { type, taskId })
      .then((response) => {
        if (response.status === 'OK') {
          console.log(response);
        }
      });
    },
    showSrtDataInTextarea() {
      if (this.srtContent.length > 0) {
        const currentTime = this.convertTimecodeToNumber();

        const isNotHigherThanOtherTimecodes = this.srtPrevTimecodeNumber.some((el) => el > currentTime);

        this.srtContent.forEach((element, index) => {
          const elCurrentStartTime = this.getSrtCurrentTime(element.startTime);
          const elCurrentStopTime = this.getSrtCurrentTime(element.stopTime);
          if (this.srtContent[index + 1] != null) {
            const nextStartTime = this.getSrtCurrentTime(this.srtContent[index + 1].startTime);
            if (currentTime >= elCurrentStopTime && currentTime <= nextStartTime && !isNotHigherThanOtherTimecodes) {
              this.srtTextInTextarea = '';
              this.srtTextIndex = null;
            }
          }

          if (currentTime >= elCurrentStartTime && currentTime <= elCurrentStopTime && !isNotHigherThanOtherTimecodes) {
            this.srtTextInTextarea = element.text;
            this.srtTextIndex = index;
          }
        });

        const lastSrtContentTime = this.getSrtCurrentTime(this.srtContent[this.srtContent.length - 1].stopTime);
        if (currentTime > lastSrtContentTime && this.srtTextInTextarea !== '') {
          this.srtTextInTextarea = '';
          this.srtTextIndex = null;
        }
        // TO AVOID AFECTING TEXT IN TEXTAREA WHEN IT'S LESS THAN THE PREVIOUS NUMBER
        this.srtPrevTimecodeNumber.push(currentTime);
      }
    },
    showCsvDataInTextarea() {
      if (this.csvContent.length > 0) {
        const currentTime = this.convertTimecodeToNumber();
        // GETTING THE POSITION OF TIMES AND ORIGINAL TEXT
        this.titlesData = {};
        this.csvContent[0].forEach((element, index) => {
          if (element === 'Start Time') {
            this.titlesData.startTimeInd = index;
          } else if (element === 'Original Text') {
            this.titlesData.originalTextInd = index;
          }
          return this.titlesData;
        });

        const startTimes = [];
        for (let index = 1; index < this.csvContent.length; index += 1) {
            const timeAsNumber = this.convertCsvTimeToNumber(this.csvContent[index][this.titlesData.startTimeInd]);
            startTimes.push(timeAsNumber);
        }

        let closestTime;
        if (this.csvPrevTimecodeNumber.length > 0) {
          const isNotHigherThanOtherTimecodes = this.csvPrevTimecodeNumber.some((el) => el > currentTime);

          if (!isNotHigherThanOtherTimecodes) {
            closestTime = startTimes.reduce((prev, curr) => (Math.abs(curr - currentTime) < Math.abs(prev - currentTime) ? curr : prev));
          }
        }

        for (let index = 1; index < this.csvContent.length; index += 1) {
          const csvCurrentTime = this.convertCsvTimeToNumber(this.csvContent[index][this.titlesData.startTimeInd]);
          if (closestTime === csvCurrentTime) {
            this.csvTextInTextarea = this.csvContent[index][this.titlesData.originalTextInd];
            this.csvTextIndex = index;
          }
        }

        // TO AVOID AFECTING TEXT IN TEXTAREA WHEN IT'S LESS THAN THE PREVIOUS NUMBER
        this.csvPrevTimecodeNumber.push(currentTime);
      }
    },
    convertCsvTimeToNumber(csvTime) {
      const formattedCsv = csvTime.replace(/:([^:]*)$/, '.$1');
      const startTimeArr = formattedCsv.split(':');
      const elCurrentStartTimeStr = startTimeArr.join('');
      const elCurrentStartTime = Number(elCurrentStartTimeStr);

      return elCurrentStartTime;
    },
    convertTimecodeToNumber() {
      const currentTimeArr = this.currentTimecode.split(':');
      const currentTimeStr = currentTimeArr.join('');
      const currentTime = Number(currentTimeStr);

      return currentTime;
    },
    getSrtCurrentTime(time) {
        const startTimeWithoutComma = time.replace(',', '.');
        const startTimeArr = startTimeWithoutComma.split(':');
        const elCurrentStartTimeStr = startTimeArr.join('');
        const elCurrentStartTime = Number(elCurrentStartTimeStr);

        return elCurrentStartTime;
    },
    saveTextIntoSrtArray() {
      if (this.srtContent[this.srtTextIndex] != null) {
        this.srtContent[this.srtTextIndex].text = this.srtTextInTextarea;
      }
    },
    saveTextIntoCsvArray() {
      if (this.csvContent[this.csvTextIndex][this.titlesData.originalTextInd] != null) {
        this.csvContent[this.csvTextIndex][this.titlesData.originalTextInd] = this.csvTextInTextarea;
      }
    },
    modifyPrevTimecodesNumber() {
      this.csvPrevTimecodeNumber = this.csvPrevTimecodeNumber.filter((el) => el <= this.changedTime);
      this.srtPrevTimecodeNumber = this.srtPrevTimecodeNumber.filter((el) => el <= this.changedTime);
    },
    saveFilesContent() {
      if (this.csvContent.length > 0 && this.srtContent.length > 0) {
            const srtPromise = new Promise((resolve, reject) => {
              resolve(this.editSrt());
            });
            const csvPromise = new Promise((resolve, reject) => {
              resolve(this.editCsv());
            });
            const promises = [srtPromise, csvPromise];

            Promise.allSettled(promises)
              .then((results) => results.forEach((result) => {
                // console.log(result);
              }));
          } else if (this.csvContent.length > 0 && this.srtContent.length <= 0) {
            this.editCsv();
          } else if (this.csvContent.length <= 0 && this.srtContent.length > 0) {
            this.editSrt();
          }
    },
  },

  mounted() {
    if (this.taskId) {
      this.getInfos(this.taskId);
    }
  },

  props: {
    taskId: {
      type: String,
      required: true,
    },
    deliveryIndex: {
      type: Number,
      required: true,
    },
    isClientReview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
  .card-header:first-child {
    font-weight: 700 !important;
  }

  .card-header > div {
    height: 2rem !important;
  }

  .message-box {
    max-width: 50%;
    text-align: left;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: #FFFFFF;
    font-weight: bold;
    background-color: #FE6464;
  }
  .subs-textareas {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
</style>
